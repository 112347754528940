export const translations = {
    
        en: {
          Dashboard: "Dashboard",
          Mines: "Suppliers",
          Kyc:"Kyc",
          Search: "Search",
          nomine: "There are no mine sites associated with",
          BasicInfo: "Basic Info",
          MineName: "Mine Name",
          MineAddress: "Mine Address",
          Mineral: "Mineral",
          Note: "Note",
          Assessments: "Assessments",
          AssessmentStartDate: "Assessment Start Date",	
          AssessmentEndDate: "Assessment End Date",
          AssessmentType: "Assessment Type",	
          Action: "Action",
          Incidents: "Incidents",
          Noincidents: "No incidents",
          Therearenoincidents: "There are no incidents to display yet",
          Gallery: "Gallery",
          Pictures: "Pictures",
          NoPictures: "No Pictures",
          Therearenopictures: "There are no pictures to display",
          Videos: "Videos",
          Therearenovideos: "There are no videos to display",
          "There are no assessments to display.": "There are no assessments to display.",
          Showing: "Showing",
          To: "to",
          Of: "of",
          Entries: "entries",
          Previous: "previous",
          Next: "next",
          "Full Name": "Full Name",
          "First Name": "First Name",
          "Last Name": "Last Name",
          Contact: "Contact",
          Email: "Email",
          Gender: "Gender",
          "National ID": "National ID",
          "National ID (Back)": "National ID (Back)",
          "Criminal Record": "Criminal Record",
          "Application Letter": "Application Letter",
          "Profile Picture": "Profile Picture",
          "Former carte d'expart": "Former carte d'expart",
          "Substance to be Mined": "Substance to be Mined",
          "Tax System": "Tax System",
          "Claimant Identification": "Claimant Identification",
          "Date of Birth": "Date of Birth",
          "Place of Birth": "Place of Birth",
          "Permanent residence": "Permanent residence",
          "Identification Card": "Identification Card",
          "Identification Card Number": "Identification Card Number",
          "Former Authorization No.": "Former Authorization No.",
          Address: "Address",
          "Identification of the requested site": "Identification of the requested site",
          "Mine does not have any miners on record.": "Mine does not have any miners on record.",
          Documents: "Documents",
          Shareholders: "Shareholders",
          BeneficialOwners: "Beneficial Owners",
          CompanyName: "Company Name",
          CompanyAddress: "Company Address",
          CompanyCountry: "Company Country",
          CompanyNumber: "Company Number",
          CompanyType: "Company Type",
          NoDocuments: "No documents available",
          Name: "Name",
          PercentageOwned: "Percentage Owned",
          Nationality: "Nationality",
          Address: "Address",
          NoShareholders: "No shareholders available",
          NoBeneficialOwners: "No Beneficial Owners available",
          Download: "Download",
          View: "View",
        },
        fr: {
          Dashboard: "Tableau de bord",
          Mines: "Fournisseurs",
          Kyc:"Kyc",
          Search: "Recherche",
          nomine: "Il n'y a aucun site minier associé à",
          BasicInfo: "Informations de base",
          MineName: "Nom de la mine",
          MineAddress: "Adresse de la mine",
          Mineral: "Minéral",
          Note: "Note",
          Assessments: "Évaluations",
          AssessmentStartDate: "Date de début de l'évaluation",
          AssessmentEndDate: "Date de fin de l'évaluation",
          AssessmentType: "Type d'évaluation",
          Action: "Action",
          Incidents: "Incidents",
          Noincidents: "Aucun incident",
          Therearenoincidents: "Il n'y a pas encore d'incidents à afficher.",
          Gallery: "Galerie",
          Pictures: "Images",
          NoPictures: "Pas d'images",
          Therearenopictures: "Il n'y a pas d'images à afficher pour le moment.",
          Videos: "Vidéos",
          Therearenovideos: "Il n'y a pas encore de vidéos à afficher.",
          "There are no assessments to display.": "Il n'y a aucune évaluation à afficher.",
          Showing: "Affichage de",
          To: "à",
          Of: "de",
          Entries: "entrées",
          Previous: "précédent",
          Next: "suivant",
          "Full Name": "Nom complet",
          "First Name": "Prénom",
          "Last Name": "Nom de famille",
          Contact: "Contact",
          Email: "Courriel",
          Gender: "Sexe",
          "National ID": "Carte d'identité nationale",
          "National ID (Back)": "Carte d'identité nationale (verso)",
          "Criminal Record": "Casier judiciaire",
          "Application Letter": "Lettre de candidature",
          "Profile Picture": "Photo de profil",
          "Former carte d'expart": "Ancienne carte d'expart",
          "Substance to be Mined": "Substance à extraire",
          "Tax System": "Système fiscal",
          "Claimant Identification": "Identification du demandeur",
          "Date of Birth": "Date de naissance",
          "Place of Birth": "Lieu de naissance",
          "Permanent residence": "Résidence permanente",
          "Identification Card": "Carte d'identité",
          "Identification Card Number": "Numéro de la carte d'identité",
          "Former Authorization No.": "Ancien numéro d'autorisation",
          Address: "Adresse",
          "Identification of the requested site": "Identification du site demandé",
          "Mine does not have any miners on record.": "La mine n'a aucun mineur enregistré.",
          Documents: "Documents",
          Shareholders: "Actionnaires",
          BeneficialOwners: "Bénéficiaires effectifs",
          CompanyName: "Nom de l'entreprise",
          CompanyAddress: "Adresse de l'entreprise",
          CompanyCountry: "Pays de l'entreprise",
          CompanyNumber: "Numéro d'entreprise",
          CompanyType: "Type d'entreprise",
          NoDocuments: "Aucun document disponible",
          Name: "Nom",
          PercentageOwned: "Pourcentage détenu",
          Nationality: "Nationalité",
          Address: "Adresse",
          NoShareholders: "Aucun actionnaire disponible",
          NoBeneficialOwners: "Aucun bénéficiaire effectif disponible",
          Download: "Télécharger",
          View: "Voir",
        }
      
      
  };